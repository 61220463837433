
import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

let routes = [{
	name: 'main',
	path: '/',
	component: () => import('layouts/MainLayout.vue'),
	children: [
		//Dashboard routes


//audits routes
		{ path: '/audits', name: 'auditslist', component: () => import('pages/audits/list.vue'), props: true },
		{ path: '/audits/index/:fieldName?/:fieldValue?', component: () => import('pages/audits/list.vue'), props: true },
		{ path: '/audits/view/:id', name: 'auditsview', component: () => import('pages/audits/view.vue'), props: true },

//brandcars routes
		{ path: '/brandcars', name: 'brandcarslist', component: () => import('pages/brandcars/list.vue'), props: true },
		{ path: '/brandcars/index/:fieldName?/:fieldValue?', component: () => import('pages/brandcars/list.vue'), props: true },
		{ path: '/brandcars/view/:id', name: 'brandcarsview', component: () => import('pages/brandcars/view.vue'), props: true },
		{ path: '/brandcars/add', name: 'brandcarsadd', component: () => import('pages/brandcars/add.vue'), props: true },
		{ path: '/brandcars/edit/:id', name: 'brandcarsedit', component: () => import('pages/brandcars/edit.vue'), props: true },

//cars routes
		{ path: '/cars', name: 'carslist', component: () => import('pages/cars/list.vue'), props: true },
		{ path: '/cars/index/:fieldName?/:fieldValue?', component: () => import('pages/cars/list.vue'), props: true },
		{ path: '/cars/view/:id', name: 'carsview', component: () => import('pages/cars/view.vue'), props: true },
		{ path: '/cars/add', name: 'carsadd', component: () => import('pages/cars/add.vue'), props: true },
		{ path: '/cars/edit/:id', name: 'carsedit', component: () => import('pages/cars/edit.vue'), props: true },
		{ path: '/cars/home', name: 'carshome', component: () => import('pages/cars/home.vue'), props: true },
		{ path: '/cars/admin', name: 'carsadmin', component: () => import('pages/cars/admin.vue'), props: true },
		{ path: '/cars/adminview/:id', name: 'carsadminview', component: () => import('pages/cars/adminview.vue'), props: true },
		{ path: '/cars/redirect', name: 'carsredirect', component: () => import('pages/cars/redirect.vue'), props: true },
		{ path: '/cars/viewtwo/:id', name: 'carsviewtwo', component: () => import('pages/cars/viewtwo.vue'), props: true },

//cars_history routes
		{ path: '/cars_history', name: 'cars_historylist', component: () => import('pages/cars_history/list.vue'), props: true },
		{ path: '/cars_history/index/:fieldName?/:fieldValue?', component: () => import('pages/cars_history/list.vue'), props: true },
		{ path: '/cars_history/view/:id', name: 'cars_historyview', component: () => import('pages/cars_history/view.vue'), props: true },
		{ path: '/cars_history/add', name: 'cars_historyadd', component: () => import('pages/cars_history/add.vue'), props: true },
		{ path: '/cars_history/edit/:id', name: 'cars_historyedit', component: () => import('pages/cars_history/edit.vue'), props: true },

//clients routes
		{ path: '/clients', name: 'clientslist', component: () => import('pages/clients/list.vue'), props: true },
		{ path: '/clients/index/:fieldName?/:fieldValue?', component: () => import('pages/clients/list.vue'), props: true },
		{ path: '/clients/view/:id', name: 'clientsview', component: () => import('pages/clients/view.vue'), props: true },
		{ path: '/clients/add', name: 'clientsadd', component: () => import('pages/clients/add.vue'), props: true },
		{ path: '/clients/edit/:id', name: 'clientsedit', component: () => import('pages/clients/edit.vue'), props: true },

//customers routes
		{ path: '/customers', name: 'customerslist', component: () => import('pages/customers/list.vue'), props: true },
		{ path: '/customers/index/:fieldName?/:fieldValue?', component: () => import('pages/customers/list.vue'), props: true },
		{ path: '/customers/view/:id', name: 'customersview', component: () => import('pages/customers/view.vue'), props: true },
		{ path: '/customers/add', name: 'customersadd', component: () => import('pages/customers/add.vue'), props: true },
		{ path: '/customers/edit/:id', name: 'customersedit', component: () => import('pages/customers/edit.vue'), props: true },
		{ path: '/customers/fatura/:id', name: 'customersfatura', component: () => import('pages/customers/fatura.vue'), props: true },
		{ path: '/customers/pickup_calendar', name: 'customerspickup_calendar', component: () => import('pages/customers/pickup_calendar.vue'), props: true },
		{ path: '/customers/dropoff_calendar', name: 'customersdropoff_calendar', component: () => import('pages/customers/dropoff_calendar.vue'), props: true },
		{ path: '/customers/pranimi/:id', name: 'customerspranimi', component: () => import('pages/customers/pranimi.vue'), props: true },
		{ path: '/customers/vonesat', name: 'customersvonesat', component: () => import('pages/customers/vonesat.vue'), props: true },
		{ path: '/customers/sot', name: 'customerssot', component: () => import('pages/customers/sot.vue'), props: true },
		{ path: '/customers/copy/:id', name: 'customerscopy', component: () => import('pages/customers/copy.vue'), props: true },
		{ path: '/customers/edit_new/:id', name: 'customersedit_new', component: () => import('pages/customers/edit_new.vue'), props: true },

//extra_features routes
		{ path: '/extra_features', name: 'extra_featureslist', component: () => import('pages/extra_features/list.vue'), props: true },
		{ path: '/extra_features/index/:fieldName?/:fieldValue?', component: () => import('pages/extra_features/list.vue'), props: true },
		{ path: '/extra_features/view/:id', name: 'extra_featuresview', component: () => import('pages/extra_features/view.vue'), props: true },
		{ path: '/extra_features/add', name: 'extra_featuresadd', component: () => import('pages/extra_features/add.vue'), props: true },
		{ path: '/extra_features/edit/:id', name: 'extra_featuresedit', component: () => import('pages/extra_features/edit.vue'), props: true },

//locations routes
		{ path: '/locations', name: 'locationslist', component: () => import('pages/locations/list.vue'), props: true },
		{ path: '/locations/index/:fieldName?/:fieldValue?', component: () => import('pages/locations/list.vue'), props: true },
		{ path: '/locations/view/:id', name: 'locationsview', component: () => import('pages/locations/view.vue'), props: true },
		{ path: '/locations/add', name: 'locationsadd', component: () => import('pages/locations/add.vue'), props: true },
		{ path: '/locations/edit/:id', name: 'locationsedit', component: () => import('pages/locations/edit.vue'), props: true },

//model_has_permissions routes
		{ path: '/model_has_permissions', name: 'model_has_permissionslist', component: () => import('pages/model_has_permissions/list.vue'), props: true },
		{ path: '/model_has_permissions/index/:fieldName?/:fieldValue?', component: () => import('pages/model_has_permissions/list.vue'), props: true },
		{ path: '/model_has_permissions/view/:id', name: 'model_has_permissionsview', component: () => import('pages/model_has_permissions/view.vue'), props: true },
		{ path: '/model_has_permissions/add', name: 'model_has_permissionsadd', component: () => import('pages/model_has_permissions/add.vue'), props: true },
		{ path: '/model_has_permissions/edit/:id', name: 'model_has_permissionsedit', component: () => import('pages/model_has_permissions/edit.vue'), props: true },

//model_has_roles routes
		{ path: '/model_has_roles', name: 'model_has_roleslist', component: () => import('pages/model_has_roles/list.vue'), props: true },
		{ path: '/model_has_roles/index/:fieldName?/:fieldValue?', component: () => import('pages/model_has_roles/list.vue'), props: true },
		{ path: '/model_has_roles/view/:id', name: 'model_has_rolesview', component: () => import('pages/model_has_roles/view.vue'), props: true },
		{ path: '/model_has_roles/add', name: 'model_has_rolesadd', component: () => import('pages/model_has_roles/add.vue'), props: true },
		{ path: '/model_has_roles/edit/:id', name: 'model_has_rolesedit', component: () => import('pages/model_has_roles/edit.vue'), props: true },

//orders routes
		{ path: '/orders', name: 'orderslist', component: () => import('pages/orders/list.vue'), props: true },
		{ path: '/orders/index/:fieldName?/:fieldValue?', component: () => import('pages/orders/list.vue'), props: true },
		{ path: '/orders/view/:id', name: 'ordersview', component: () => import('pages/orders/view.vue'), props: true },
		{ path: '/orders/add', name: 'ordersadd', component: () => import('pages/orders/add.vue'), props: true },
		{ path: '/orders/edit/:id', name: 'ordersedit', component: () => import('pages/orders/edit.vue'), props: true },

//permissions routes
		{ path: '/permissions', name: 'permissionslist', component: () => import('pages/permissions/list.vue'), props: true },
		{ path: '/permissions/index/:fieldName?/:fieldValue?', component: () => import('pages/permissions/list.vue'), props: true },
		{ path: '/permissions/view/:id', name: 'permissionsview', component: () => import('pages/permissions/view.vue'), props: true },
		{ path: '/permissions/add', name: 'permissionsadd', component: () => import('pages/permissions/add.vue'), props: true },
		{ path: '/permissions/edit/:id', name: 'permissionsedit', component: () => import('pages/permissions/edit.vue'), props: true },

//public routes
		{ path: '/public', name: 'publiclist', component: () => import('pages/publiccars/list.vue'), props: true },
		{ path: '/public/index/:fieldName?/:fieldValue?', component: () => import('pages/publiccars/list.vue'), props: true },

//rent routes
		{ path: '/rent', name: 'rentlist', component: () => import('pages/rent/list.vue'), props: true },
		{ path: '/rent/index/:fieldName?/:fieldValue?', component: () => import('pages/rent/list.vue'), props: true },

//role_has_permissions routes
		{ path: '/role_has_permissions', name: 'role_has_permissionslist', component: () => import('pages/role_has_permissions/list.vue'), props: true },
		{ path: '/role_has_permissions/index/:fieldName?/:fieldValue?', component: () => import('pages/role_has_permissions/list.vue'), props: true },
		{ path: '/role_has_permissions/view/:id', name: 'role_has_permissionsview', component: () => import('pages/role_has_permissions/view.vue'), props: true },
		{ path: '/role_has_permissions/add', name: 'role_has_permissionsadd', component: () => import('pages/role_has_permissions/add.vue'), props: true },
		{ path: '/role_has_permissions/edit/:id', name: 'role_has_permissionsedit', component: () => import('pages/role_has_permissions/edit.vue'), props: true },

//roles routes
		{ path: '/roles', name: 'roleslist', component: () => import('pages/roles/list.vue'), props: true },
		{ path: '/roles/index/:fieldName?/:fieldValue?', component: () => import('pages/roles/list.vue'), props: true },
		{ path: '/roles/view/:id', name: 'rolesview', component: () => import('pages/roles/view.vue'), props: true },
		{ path: '/roles/add', name: 'rolesadd', component: () => import('pages/roles/add.vue'), props: true },
		{ path: '/roles/edit/:id', name: 'rolesedit', component: () => import('pages/roles/edit.vue'), props: true },

//transactions routes
		{ path: '/transactions', name: 'transactionslist', component: () => import('pages/transactions/list.vue'), props: true },
		{ path: '/transactions/index/:fieldName?/:fieldValue?', component: () => import('pages/transactions/list.vue'), props: true },
		{ path: '/transactions/view/:id', name: 'transactionsview', component: () => import('pages/transactions/view.vue'), props: true },
		{ path: '/transactions/add', name: 'transactionsadd', component: () => import('pages/transactions/add.vue'), props: true },
		{ path: '/transactions/edit/:id', name: 'transactionsedit', component: () => import('pages/transactions/edit.vue'), props: true },

//transactions2 routes
		{ path: '/transactions2', name: 'transactions2list', component: () => import('pages/transactions2/list.vue'), props: true },
		{ path: '/transactions2/index/:fieldName?/:fieldValue?', component: () => import('pages/transactions2/list.vue'), props: true },
		{ path: '/transactions2/view/:id', name: 'transactions2view', component: () => import('pages/transactions2/view.vue'), props: true },
		{ path: '/transactions2/add', name: 'transactions2add', component: () => import('pages/transactions2/add.vue'), props: true },
		{ path: '/transactions2/edit/:id', name: 'transactions2edit', component: () => import('pages/transactions2/edit.vue'), props: true },

//users routes
		{ path: '/users', name: 'userslist', component: () => import('pages/users/list.vue'), props: true },
		{ path: '/users/index/:fieldName?/:fieldValue?', component: () => import('pages/users/list.vue'), props: true },
		{ path: '/users/view/:id', name: 'usersview', component: () => import('pages/users/view.vue'), props: true },
		{ path: '/account/edit', name: 'usersaccountedit', component: () => import('pages/account/accountedit.vue'), props: true },
		{ path: '/account', name: 'usersaccountview', component: () => import('pages/account/accountview.vue'), props: true },
		{ path: '/users/add', name: 'usersadd', component: () => import('pages/users/add.vue'), props: true },
		{ path: '/users/edit/:id', name: 'usersedit', component: () => import('pages/users/edit.vue'), props: true },

		
		
//Password reset routes
		{ path: '/index/forgotpassword', name: 'forgotpassword', component: () => import('pages/index/forgotpassword.vue') },
		{ path: '/index/resetpassword', name: 'resetpassword', component: () => import('pages/index/resetpassword.vue') },
		{ path: '/index/resetpassword_completed', name: 'resetpassword_completed', component: () => import('pages/index/resetpassword_completed.vue') },
		
		

//Error pages
		{ path:  '/error/forbidden', name: 'forbidden',  component: () => import('pages/errors/forbidden.vue') },
		{ path: '/error/notfound',  name: 'notfound', component: () => import('pages/errors/pagenotfound.vue') }
	],
	
	},
	{ path: '/:catchAll(.*)*', component: () => import('pages/errors/pagenotfound.vue') }
];

export default route(async function ({ store }) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)
	
	let mainRoute = routes.find(x => x.name = "main");
	

	let loginToken = store.getters["auth/getLoginToken"];
	if(loginToken){
		try{
			await store.dispatch('auth/getUserData'); //get current user data from api on page load
		}
		catch(e){
			store.dispatch('auth/logout'); //token might have expired
			console.error(e);
		}
	}

	const user = store.state.auth.user;
	if(user){
		let userRole = store.getters["auth/getUserRole"].toLowerCase();
		let rolePage;
		switch(userRole){
			case "admin":
				rolePage = "admin";
				break;
			default:
				rolePage = "home";
		}
		
		const homeRoute = {
			path: '/', alias: '/home', name: 'home', component: () => import(`pages/home/${rolePage}.vue`) 
		}

		mainRoute.children.push(homeRoute);
	}
	else{
		const indexRoute = { path: '/',  alias: '/home', name: 'index', component: () => import('pages/index/index.vue') }
		mainRoute.children.push(indexRoute);
	}


	const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,
    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE)
  })
  return Router
})




